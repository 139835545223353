<template>
	<v-form>
		<v-row>
			<v-col>
				<v-text-field v-model="data.title" :label="mwtr[fields.title.label]"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-textarea v-model="data.description" :label="mwtr[fields.description.label]"></v-textarea>
			</v-col>
		</v-row>
	</v-form>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "QuestionForm", 
		props: {
			question: {},
			data: {}
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },
		computed: {
			fields(){
				return this.mwsettings.fields.Question
			}
		},

	}
// </script>"