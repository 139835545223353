<template>

	<v-sheet flat class="pl-6 pr-5">	

		<v-toolbar flat>
			<v-tabs v-model="tab" class="ml-3" color="grey">
				<v-tab>{{mwtr.QuestionSetUpLabel}}</v-tab>
				<v-tab v-if="id">{{mwtr.QuestionAnswerOptionsLabel}}</v-tab>
				<v-tab v-if="id">{{mwtr.QuestionVisibleIfLabel}}</v-tab>
				<v-tab v-if="id">{{mwtr.PreviewLabel}}</v-tab>
			</v-tabs>
		</v-toolbar>
		<v-tabs-items v-model="tab">
			<v-tab-item>
				<v-card flat>
					<GeneralForm :id="id" collection="questions" :data="question">
						<template v-slot:formContent>
							<QuestionForm :question="id" :data="question"></QuestionForm>
						</template>
					</GeneralForm>
				</v-card>
			</v-tab-item>
			<v-tab-item v-if="id">
				<AnswerOptions :question="id"></AnswerOptions>
			</v-tab-item> 
			<v-tab-item v-if="id">
				<VisibleIf :question="question" :id="id"></VisibleIf>
			</v-tab-item> 
			<v-tab-item v-if="id">
				<Preview :questions="[id]"></Preview>
			</v-tab-item> 
		</v-tabs-items>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	
	import GeneralForm from "@/components/admin/forms/GeneralForm"
	import QuestionForm from "@/components/admin/forms/QuestionForm"
	import AnswerOptions from "@/components/survey/builder/AnswerOptions"
	import VisibleIf from "@/components/survey/builder/VisibleIf"
	import Preview from "@/components/survey/render/Preview"

	export default {
		name: "EditQuestion", 
		props: {
			id: {}
		},
		data: () => {
			return {
				tab: 0
			}
		},
		computed: {
			fields(){
				return this.mwsettings.getFields("Question");
			},
			question(){
				var question = this.$store.state.questions[this.id]
				return question || this.mwsettings.getEmpty("Question");
			}
		},
		components: {
			GeneralForm, 
			QuestionForm, 
			AnswerOptions,
			VisibleIf,
			Preview	
		},
		created(){
			if( this.id ){			
				this.$store.dispatch("questions/fetchOne", this.id)
			}
		},
		destroyed(){
			this.$store.dispatch("detachOne", this.id)
		},

	}
// </script>"