<template>
	<v-card class="mb-3">
		<v-row class="ig-option-tile-wrapper">
			<v-col cols="12" sm="4" md="3" lg="2" class="">
				<v-card color="primary" class="ig-option-tile active">
					{{text || "..."}}
				</v-card>
			</v-col>
			<v-col class="pt-0 pb-0 pr-5">
				<v-row class="mb-0 mt-0">
					<v-col>
						<v-text-field dense outlined v-model="text" :hint="mwtr.AnswerOptionTextHelp" :label="mwtr.AnswerOptionTextLabel"></v-text-field>
					</v-col>
				</v-row>
				<v-row class="mb-0 mt-0">
					<v-col cols="12" sm="6" lg="9" class="pb-0 pt-0">
						<v-text-field dense outlined v-model="value" append-icon="mdi-auto-fix" @click:append="generateValue" :hint="mwtr.AnswerOptionValueHelp" :label="mwtr.AnswerOptionValueLabel"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" lg="3" class="pb-0 pt-0">
						<v-text-field dense outlined v-model="score" :label="mwtr.ScoreLabel" type="number"></v-text-field>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-alert class="ma-3" dense text v-if="error" type="error">{{error}}</v-alert>
			</v-col>
		</v-row>
		<v-card-actions>
			<v-btn icon @click="$emit('up')"><v-icon>mdi-arrow-up-circle</v-icon></v-btn>
			<v-btn icon @click="$emit('down')" class="ml-0"><v-icon>mdi-arrow-down-circle</v-icon></v-btn>
			<v-spacer></v-spacer>
			<slot name="deleteButton"></slot>
			
		</v-card-actions>
	</v-card>
</template>

<style lang="less">
	@import '../../../../src/assets/css/survey/answerOptions.less';	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	export default {
		name: "AnswerOptionField", 
		props: {
			option: {}, 
			id: {},
			error: {}
		},
		data: () => {
			return {
				text: "", 
				value: "",
				score: "", 
				position: ""

			}
		},
		methods: {
			generateValue(){
				this.value = this.mwutilities.strToKey(this.text)
			},
			up(){
				this.position-- 
			},
			down(){
				this.position++
			}, 
		},
		components: {
			
		},
		// computed: {
			// ...Vuex.mapState({})
		// },
		watch: {
			text(){
				this.$emit("update", "text", this.text )
			},
			position(){
				this.$emit("update", "position", this.position )
			},
			value(){
				this.$emit("update", "value", this.value )
			},			
			score(){
				this.$emit("update", "score", this.score )
			},
		},
		created(){
			for( var key in this.option ){
				if( typeof this[key] !== 'undefined' ){
					this[key] = this.option[key]
				}
			}
		}

	}
// </script>"