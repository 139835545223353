<template>
	<v-card flat>
		<v-card-title></v-card-title>
		<v-card-text>
			<v-select label="Add new condition" :items="questionItems" v-model="questionConditional" item-text="text" item-value="value"></v-select>
			<QuestionConditional :condition="question.visibleIf" :question="questionConditional" @update="(v) => condition = v"></QuestionConditional>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="igblack" dark @click="deleteCondition">{{mwtr.DeleteButton}}</v-btn>
			<v-btn color="primary" @click="save">{{mwtr.SaveButton}}</v-btn>
		</v-card-actions>
		<Saved :trigger="true" @saved="(v) => saved = v"></Saved>
	</v-card>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import Saved from "@/components/notices/Saved"
	import QuestionConditional from "@/components/survey/builder/QuestionConditional"
	export default {
		name: "VisibleIf", 
		props: {
			id: {},
			question: {},
		},
		data: () => {
			return {
				saved: false,
				condition: "",
				questionConditional: false,
			}
		},
		components: {
			Saved,
			QuestionConditional
		},
		computed: {
			...Vuex.mapState({
				questions: state => state.questions
			}),
			questionItems(){
				const self = this;
				var order = self.mwutilities.getPositions(self.questions);
				return order.map( q => {
					return { value: q, text: self.questions[q].title }
				})
			}
		},
		methods: {
			save(){
				var self = this;
				self.$store.dispatch("updateDoc", {
					doc: `questions/${self.id}`,
					data: {visibleIf: self.condition}
				}).then(() => {
					self.saved = true;
				})
			},
			deleteCondition(){
				this.$store.dispatch("updateDoc", {
					doc: `questions/${this.id}`,
					data: {visibleIf: false}
				})	
				this.questionConditional = false;
				this.condition = ""
			}
		},
		watch: {
			question: {
				immediate: true, 
				handler(){
					if( this.question.visibleIf ){
						this.questionConditional = this.question.visibleIf.question;
					}
				}
			},
			questions: {
				immediate: true, 
				handler(){
					if( this.questionItems.length == 1 ){
						this.$store.dispatch("organisations/fetchAll")
					}
				}
			}
		}
		
	}
// </script>"