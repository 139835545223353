<template>
	<v-card>
		<ErrorMessage :trigger="error" @close="error = false"></ErrorMessage>
		<Saved :trigger="saved" @close="saved = false"></Saved>
		<v-card-text class="pa-3">
			<AnswerOptionField class="mb-4" 
			v-for="id in order" 
			:key="id" 
			:error="hasError( id)"
			:id="id" 
			:option="answerOptions[id]"
			@up="move(id, 'up')"
			@down="move(id, 'down')"
			@update="(key, value) => answerOptions[id][key] = value"
			>
				<template v-slot:deleteButton>
					<DeleteButton :collection="`questions/${question}/answerOptions`" :id="id"></DeleteButton>	
				</template>
			</AnswerOptionField>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="primary" @click="addAnswerOption">{{mwtr.AddAnswerOptionButton}}</v-btn>
			<v-btn color="primary" @click="save">{{mwtr.SaveButton}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import Saved from "@/components/notices/Saved"
	import ErrorMessage from "@/components/notices/ErrorMessage"
	import DeleteButton from "@/components/admin/buttons/DeleteButton"
	import AnswerOptionField from "@/components/survey/builder/AnswerOptionField"
	export default {
		name: "AnswerOptions", 
		props: {
			question: {},
		},
		data: () => {
			return {
				saved: false, 
				order: [],
				error: false, 
				errors: []
			}
		},
		components: {
			AnswerOptionField,
			DeleteButton,
			Saved,
			ErrorMessage
		},
		computed: {
			...Vuex.mapState({
				answerOptions( state ){
					var options = state.questions[this.question].answerOptions;
					return options
				}
			}),
			fields(){
				return this.mwsettings.fields.Question
			}, 
		},
		methods: {
			validate(){
				var errors = [];
				Object.keys(this.answerOptions).filter( v => !this.answerOptions[v].value ).forEach( e => {
					errors.push( {id: e, message: this.mwtr.ErrorMessageEmptyValue})
				})
				var values = Object.values(this.answerOptions).map( a => a.value );
				var duplicates = Object.keys(this.answerOptions).filter( (key, i) => { return values.indexOf(this.answerOptions[key].value) !== i } );
				duplicates.forEach( dupe => {
					errors.push( {id: dupe, message: this.mwtr.ErrorMessageDuplicateValue})	
				}); 
				this.errors = errors;
			},
			hasError(id){
				var error = this.errors.find( error => error.id == id )
				return  error ? error.message : false;
			},
			getOrder(){
				var options = this.mwutilities.getPositions(this.answerOptions)
				return options;				
			},
			move(id, direction){
				this.mwutilities.moveItemInArray( id, this.order, direction )
			},
			addAnswerOption(){
				var ao = this.mwsettings.getEmpty("AnswerOption");
				ao.position = this.order.length;
				this.$store.dispatch( "questions/addAnswerOption", {answer: ao, question: this.question } );
			}, 
			save(){
				this.validate();
				if( !this.errors.length ){				
					for( var key in this.answerOptions ){
						var data = this.answerOptions[key]
						data.position = this.order.indexOf(key)
						var doc = `questions/${this.question}/answerOptions/${key}`;
						this.$store.dispatch( "updateDoc", { doc, data } ).then( () => {
							this.saved = true;
						})
					}
				}
				else{
					this.error = true
				}
			}
		},
		watch: {
			answerOptions(){
				this.order = this.getOrder();
			}
		},
		created(){
			this.$store.dispatch("questions/fetchAnswerOptions", this.question )
		},
		destroyed(){
			this.$store.dispatch("detachOne", this.question+"-answers")
		},

	}
// </script>"