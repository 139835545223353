<template>
	<v-card class="mt-3 text-cener" flat v-if="question">
		<v-item-group v-model="operator" class="text-center">
			<v-item v-slot="{ active, toggle }" v-for="(ob, o) in operators" :key="o" :value="o">
				<v-chip :color="active ? 'green' : 'lightgrey'" :dark="active" :text="!active" class="mr-2"
				@click="toggle">
					{{o}}
				</v-chip>
			</v-item>
		</v-item-group>
		<v-item-group v-model="value" :multiple="operator ? operators[operator].multiple : false" class="text-center mt-6">
			<v-item v-slot="{ active, toggle }" v-for="id in answerOptionsOrder" :key="id" :value="answerOptions[id].value">
				<v-chip :color="active ? 'green' : 'lightgrey'" :dark="active" :text="!active" class="mb-2 mr-2"
				@click="toggle">
					{{answerOptions[id].text}}
				</v-chip>
			</v-item>
		</v-item-group>
	</v-card>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "QuestionConditional", 
		props: {
			condition: {},
			question: {}
		},
		data: () => {
			return {
				operator: "",
				value: "",
				operators: {
					"!=": {
						multiple: false,
					}, 
					in: {
						multiple: true, 
					}, 
					"==": {
						multiple: false
					}, 
					">": {
						multiple: false
					},
					"<": {
						multiple: false
					}
				}
					
			}
		},
		// components: {

		// },
		computed: {
			...Vuex.mapState({
				questionObject(state){
					var id = this.question;
					return state.questions[id] || {}
				}, 
			}),
			answerOptions(){
				return this.questionObject.answerOptions || false
			},
			answerOptionsOrder(){
				if( this.answerOptions ){
					return this.mwutilities.getPositions(this.answerOptions);
				}
				else{
					return []
				}
			},
			visibleIf(){
				return {
					question: this.question,
					operator: this.operator, 
					value: this.value
				}
			},
		},
		watch: {
			operator(nv, ov ){
				if( (nv && ov) && nv.multiple !== ov.multiple ){
					this.value = "";
				}
			},
			visibleIf(){
				this.$emit("update", this.visibleIf )
			}, 
			question: {
				immediate: true, 
				handler(){				
					if( this.question ){
						this.$store.dispatch("questions/fetchAnswerOptions", this.question )
					}
				}
			}
		},
		created(){
			if( this.condition ){
				if( this.condition.operator ){
					this.operator = this.condition.operator;
					this.value = this.condition.value;
				}
			}
		},
	}
// </script>"